<template>
  <div class="mid">
    <slot></slot>
  </div>
</template>

<style scoped>
.mid {
  background-image: url('/blue-watermark.png');
  width: 100%;
  background-size: 100% 535px;
  background-repeat: no-repeat;
  background-position: top center;
}
</style>
